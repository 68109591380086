<h1 mat-dialog-title>
    <span>
        {{formType === commonConstants.formTypeConstants.add ? 'Create': 'Edit'}} Lead
    </span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper lead-form-wrapper">
    <div class="overlay-loader" [busyIf]="isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #editleadForm="ngForm" novalidate autocomplete="off"
        (ngSubmit)="editleadForm.form.valid && onFormSubmit(activeTabIndex)">
        <div class="dialog-content-wrapper">
            <div class="row">
                <div class="col-12 d-flex justify-content-end active-index mb-2">
                    {{ activeTabIndex + 1 }} Of 2
                </div>
            </div>
            <div class="lead-tab-wrapper">
                <mat-tab-group [(selectedIndex)]="activeTabIndex" (selectedIndexChange)="onTabChange($event)">
                    <mat-tab label="General">
                        <div class="row">
                            <div class="d-flex justify-content-end my-3">
                                <img src="assets/images/info-circle-icon.svg"
                                    class="image-icon-primary information-icon"
                                    matTooltip="The Lead ID has been automatically generated by Leadspeed" /> Lead ID:
                                <span class="lead-id">{{leadId}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-field mb-3">
                                    <div class="label-text">Project Name <sup>*</sup></div>
                                    <kendo-textbox [(ngModel)]="editLeadFormPayload.dealname" name="dealname"
                                        autocomplete="off" [maxlength]="250" required>
                                    </kendo-textbox>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-field mb-3">
                                    <div class="label-text">Description <sup>*</sup></div>
                                    <kendo-textarea [(ngModel)]="editLeadFormPayload.long_description"
                                        name="long_description" [rows]="6" autocomplete="off" resizable="vertical"
                                        required>
                                    </kendo-textarea>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Company <sup>*</sup></div>
                                    <kendo-dropdownlist [data]="companiesDataList" [textField]="'name'"
                                        [valueField]="'id'" [valuePrimitive]="true" [loading]="isFormLoading"
                                        [defaultItem]="commonSettings.defaultItems.nameId0" [filterable]="true"
                                        [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                                        [(ngModel)]="editLeadFormPayload.company_id" name="company"
                                        (valueChange)="onCompanyChange($event)" placeholder="Select" required>
                                    </kendo-dropdownlist>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">City, State</div>
                                    <kendo-textbox [(ngModel)]="cityState" name="cityState" autocomplete="off"
                                        [disabled]="true"></kendo-textbox>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-field mb-3">
                                    <div class="label-text">Address</div>
                                    <kendo-textbox [(ngModel)]="editLeadFormPayload.address" name="address"
                                        autocomplete="off" [disabled]="true"></kendo-textbox>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Country</div>
                                    <kendo-textbox [(ngModel)]="editLeadFormPayload.country" name="country"
                                        autocomplete="off" [disabled]="true"></kendo-textbox>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Zip Code</div>
                                    <kendo-textbox [(ngModel)]="editLeadFormPayload.zip" name="zip" autocomplete="off"
                                        [disabled]="true"></kendo-textbox>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="shippingAddressDataList.length > 0 else projectShippingAddress">
                                <div class="form-field mb-3">
                                    <div class="label-text">Project Shipping Address <sup>*</sup></div>
                                    <kendo-dropdownlist [data]="shippingAddressDataList" [textField]="'value'"
                                        [valueField]="'key'" [valuePrimitive]="true" [loading]="isFormLoading"
                                        [defaultItem]="commonSettings.defaultItems.KeyValue"
                                        [(ngModel)]="editLeadFormPayload.shipping_address_id"
                                        name="project_shipping_address" placeholder="Select" required>
                                    </kendo-dropdownlist>
                                </div>
                            </div>
                            <ng-template #projectShippingAddress>
                                <div class="col-12">
                                    <div class="form-field mb-3">
                                        <div class="label-text">Project Shipping Address</div>
                                        <kendo-dropdownlist [data]="shippingAddressDataList" [textField]="'value'"
                                            [valueField]="'key'" [valuePrimitive]="true" [loading]="isFormLoading"
                                            [defaultItem]="commonSettings.defaultItems.KeyValue"
                                            [(ngModel)]="editLeadFormPayload.shipping_address_id1"
                                            name="project_shipping_address1" placeholder="Select">
                                        </kendo-dropdownlist>
                                    </div>
                                </div>
                            </ng-template>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Rep Company</div>
                                    <kendo-dropdownlist [data]="companiesDataList" [textField]="'name'" [valueField]="'id'"
                                        [valuePrimitive]="true" [loading]="isFormLoading"
                                        [defaultItem]="commonSettings.defaultItems.fieldsNameId" [filterable]="true"
                                        [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                                        [(ngModel)]="editLeadFormPayload.rep_company_id" name="rep_company" placeholder="Select">
                                    </kendo-dropdownlist>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Month Of Budget Year End</div>
                                    <kendo-dropdownlist [data]="dropDownSourceList?.month_of_budget_year_end" [textField]="'value'"
                                        [valueField]="'key'" [valuePrimitive]="true" [loading]="isFormLoading"
                                        [defaultItem]="commonSettings.defaultItems.KeyValue"
                                        [(ngModel)]="editLeadFormPayload.month_of_budget_year_end"
                                        name="month_of_budget_year_end" placeholder="Select">
                                    </kendo-dropdownlist>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Marketing Program</div>
                                    <kendo-dropdownlist [data]="dropDownSourceList?.marketing_program" [textField]="'key'"
                                        [valueField]="'value'" [valuePrimitive]="true" [loading]="isFormLoading"
                                        [defaultItem]="commonSettings.defaultItems.fieldsValueKey"
                                        [(ngModel)]="editLeadFormPayload.marketing_program" name="marketing_program"
                                        placeholder="Select">
                                    </kendo-dropdownlist>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Business Development</div>
                                    <kendo-dropdownlist [data]="dropDownSourceList?.business_development" [textField]="'key'"
                                        [valueField]="'value'" [valuePrimitive]="true" [loading]="isFormLoading"
                                        [defaultItem]="commonSettings.defaultItems.fieldsValueKey"
                                        [(ngModel)]="editLeadFormPayload.business_development"
                                        name="business_development" placeholder="Select">
                                    </kendo-dropdownlist>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Referred By</div>
                                    <kendo-textbox [(ngModel)]="editLeadFormPayload.referred_by" name="referred_by"
                                        [maxlength]="250" autocomplete="off">
                                    </kendo-textbox>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Lead Stage</div>
                                    <kendo-dropdownlist [data]="leadStageDataSource"
                                        [(ngModel)]="editLeadFormPayload.deal_stage" [textField]="'text'"
                                        [valueField]="'value'" [valuePrimitive]="true"
                                        [defaultItem]="commonSettings.defaultItems.textValue" name="deal_stage">
                                    </kendo-dropdownlist>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Lead Stage Date</div>
                                    <kendo-datepicker [(ngModel)]="editLeadFormPayload.deal_stage_date"
                                        name="deal_stage_date" [readOnlyInput]="true" [format]="dateFormat"
                                        [placeholder]="dateFormat" [navigation]="false">
                                    </kendo-datepicker>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Bid Date</div>
                                    <kendo-datepicker [(ngModel)]="editLeadFormPayload.bid_date" name="bid_date"
                                        [readOnlyInput]="true" [format]="dateFormat" [placeholder]="dateFormat"
                                        [navigation]="false">
                                    </kendo-datepicker>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Expected Close</div>
                                    <kendo-datepicker [(ngModel)]="editLeadFormPayload.deal_closed_date"
                                        name="deal_closed_date" [readOnlyInput]="true" [format]="dateFormat"
                                        [placeholder]="dateFormat" [navigation]="false">
                                    </kendo-datepicker>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Validity Expiration Date</div>
                                    <kendo-datepicker [(ngModel)]="editLeadFormPayload.expiration_date"
                                        name="expiration_date" [readOnlyInput]="true" [format]="dateFormat"
                                        [placeholder]="dateFormat" [navigation]="false">
                                    </kendo-datepicker>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">RSM <sup>*</sup></div>
                                    <kendo-dropdownlist 
                                        [data]="leadOwnerDataList"
                                        [(ngModel)]="editLeadFormPayload.deal_owner_id" 
                                        [textField]="'name'"
                                        [valueField]="'id'" 
                                        [valuePrimitive]="true" 
                                        [loading]="isFormLoading"
                                        [filterable]="true"
                                        [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                                        [defaultItem]="commonSettings.defaultItems.idName" 
                                        name="deal_owner_id"
                                        required>
                                    </kendo-dropdownlist>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Status</div>
                                    <kendo-dropdownlist #statusDropdownlist [data]="dropDownSourceList?.status"
                                        [textField]="'value'" [valueField]="'key'" [valuePrimitive]="true"
                                        [(ngModel)]="editLeadFormPayload.status" name="status"
                                        [defaultItem]="commonSettings.defaultItems.KeyValue">
                                    </kendo-dropdownlist>
                                </div>
                            </div>
                            <ng-container *ngIf="editLeadFormPayload.status === 'Closed'">
                                <div class="col-6">
                                    <div class="form-field mb-3">
                                        <div class="label-text">Closed Reason <sup>*</sup></div>
                                        <kendo-dropdownlist #closedReasonDropdownlist [data]="dropDownSourceList?.closed_reason"
                                            [textField]="'value'" [valueField]="'key'" [valuePrimitive]="true"
                                            [defaultItem]="commonSettings.defaultItems.KeyValue"
                                            [(ngModel)]="editLeadFormPayload.closed_reason" name="closed_reason"
                                            required>
                                        </kendo-dropdownlist>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Startup Date</div>
                                    <kendo-datepicker [(ngModel)]="editLeadFormPayload.startup_date" name="startup_date"
                                        [readOnlyInput]="true" [format]="dateFormat" [placeholder]="dateFormat"
                                        [navigation]="false">
                                    </kendo-datepicker>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-field mb-3">
                                    <div class="label-text">Startup Tech</div>
                                    <kendo-dropdownlist [data]="leadOwnerDataList" [(ngModel)]="editLeadFormPayload.startup_tech"
                                        [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true" [loading]="isFormLoading"
                                        [filterable]="true" [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                                        [defaultItem]="commonSettings.defaultItems.idName" name="startup_tech">
                                    </kendo-dropdownlist>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-field mb-3">
                                    <div class="label-text">Serial Numbers</div>
                                    <kendo-textarea [(ngModel)]="editLeadFormPayload.serial_numbers" name="serial_numbers" [rows]="6"
                                        autocomplete="off" resizable="vertical">
                                    </kendo-textarea>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Stakeholders">
                        <div class="contact-wrapper">
                            <div class="label-text my-3">Contact</div>
                            <div class="row mb-3">
                                <div class="col-6 p-0">
                                    <div class="form-field mb-0">
                                        <kendo-dropdownlist 
                                            [data]="contactList" 
                                            [textField]="'name'"
                                            [valueField]="'contact_id'" 
                                            [valuePrimitive]="true"
                                            [defaultItem]="commonSettings.defaultItems.contactIdName"
                                            [filterable]="true"
                                            [itemDisabled]="itemDisabled"
                                            [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                                            [(ngModel)]="selectedContact"
                                            name="contact_name"
                                            placeholder="Select"
                                            [loading]="isFormLoading"
                                            [virtual]="{ itemHeight: 30, pageSize: 50 }">
                                        </kendo-dropdownlist>
                                    </div>
                                </div>
                                <div class="col-6 p-0 d-flex justify-content-end align-items-center">
                                    <div class="button-wrapper">
                                        <span class="icon-button-wrapper">
                                            <button mat-flat-button type="button" color="primary"
                                                (click)="addContacts()" [disabled]="!selectedContact"
                                                matTooltip="Add Stakeholder">
                                                <img src="assets/images/add-circle-icon.svg" height="20"
                                                    class="add-icon" />
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive mt-2" *ngIf="editLeadFormPayload.stakeholder.length > 0">
                                <table class="table associate-table">
                                    <thead>
                                        <tr class="table-header">
                                            <th>Contact</th>
                                            <th>Company</th>
                                            <th>Industry Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let contact of editLeadFormPayload.stakeholder let i = index">
                                            <td class="wrap-contact-company" [matTooltip]="contact.name">
                                                <a class="navigate-link d-block"
                                                    (click)="onRedirectContactEditForm(contact, commonConstants.formTypeConstants.edit)">
                                                    {{ contact.name }}
                                                </a>
                                            </td>
                                            <td class="wrap-contact-company" [matTooltip]="contact.company_name">{{
                                                contact.company_name }}</td>
                                            <td>{{ contact.industry_type }}</td>
                                            <td>
                                                <div class="grid-icon-btn-wrapper">
                                                    <button mat-flat-button type="button" color="input-color"
                                                        matTooltip="Delete Association" class="stack-delete-icon m-0"
                                                        (click)="onDeleteAssociation([i])">
                                                        <img src="assets/images/delete-icon.svg" height="24" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button *ngIf="activeTabIndex === 0" type="button" color="secondary" (click)="onClose()">
                Cancel
            </button>
            <button mat-flat-button *ngIf="activeTabIndex === 0" type="submit" color="primary" (click)="onNext()"
                [disabled]="!editleadForm.form.valid">
                Save
            </button>
            <button mat-flat-button *ngIf="activeTabIndex === 1" type="button" color="secondary" (click)="onBack()">
                Back
            </button>
            <button mat-flat-button *ngIf="activeTabIndex === 1" type="submit" color="primary">
                Save
            </button>
        </div>
    </form>
</div>