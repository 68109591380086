export class LeadLineDataModel {

    public leadLineNavigationModel: any = [
        {
            label: 'ID Info',
            route: 'id-info',
            isDisabled: false,
        },
        {
            label: 'Part Info',
            route: 'part-info',
            isDisabled: false,
        },
        {
            label: 'Revenue Info',
            route: 'revenue-info',
            isDisabled: false,
        },
        {
            label: 'Project Info',
            route: 'project-info',
            isDisabled: false,
        },
        {
            label: 'Screen/Compactor Details',
            route: 'screen-info',
            isDisabled: false,
        },
        {
            label: 'Grit Equipment Details',
            route: 'grit-equipment-info',
            isDisabled: false,
        },
        {
            label: 'Equipment Info',
            route: 'equipment-info',
            isDisabled: false,
        },
        {
            label: 'Bid Info',
            route: 'bid-info',
            isDisabled: false,
        },
        {
            label: 'Controls',
            route: 'controls-info',
            isDisabled: false,
        },
        {
            label: 'Pricing Info',
            route: 'pricing-info',
            isDisabled: false,
        }
    ]

    public leadDetailsHeaderModel: any = {
        lead_id: '',
        leadline_id: '',
        part_id: '',
        quantity: '',
        score: 0,
        projected_revenue: '',
        is_active: true
    }

    public idInfoFormPayload: any = {
        leadId: '',
        leadlineId: '',
        timezone: new Date().getTimezoneOffset().toString()
    }

    public partInfoFormPayload: any = {
        lolpart_id: '',
        lolpart_revision_id: '',
        loldescription: '',
        lolunit_of_measure: '',
        lolpart_group_id: '',
        lolquantity: '',
        lolunique_id: '',
    }

    public revenueInfoFormPayload: any = {
        leadline_amount: '0',
        input_from: '',
    }

    public projectInfoFormPayload: any = {
        ulol_sourceofwaste: '',
        ulol_typeofflow: '',
        ulol_collectionsystem: '',
        ulol_pretreatment: '',
        ulol_peakflow: '',
        ulol_peakflowum: '',
        ulol_avgflow: '',
        ulol_avgflowum: '',
        ulol_channelwidth: '',
        ulol_channelwidthum: '',
        ulol_channelwidthdesign: '',
        ulol_channelrecess: '',
        ulol_channelrecessum: '',
        ulol_channelheight: '',
        ulol_channelheightum: '',
        ulol_channelheightdesign: '',
        ulol_minupdepthpeak: '',
        ulol_minupdepthpeakum: '',
        ulol_maxupdepth: '',
        ulol_maxupdepthum: '',
        ulol_mindowndepthpeak: '',
        ulol_mindowndepthpeakum: '',
        ulol_maxdowndepth: '',
        ulol_maxdowndepthum: '',
        ulol_maxheadloss: '',
        ulol_maxheadlossum: '',
        ulol_blindingstated: '',
        ulol_equiploc: '',
        ulol_classofenviro: '',
        ulol_classofenviroo: '',
        ulol_dischargeheight: '',
        ulol_dischargeheightum: '',
        ulol_dischargeinto: '',
        ulol_matlreq: '',
        ulol_matlreqo: ''
    }

    public screenInfoFormPayload: any = {
        ulol_distance: '',
        ulol_distanceum: '',
        ulol_channelsealheight: '',
        ulol_channelsealheightum: '',
        ulol_maxsolidsize: '',
        ulol_maxsolidsizeum: '',
        ulol_wcdrainlocation: '',
        ulol_dischargedistance: '',
        ulol_dischargedistanceum: '',
        ulol_openingsize: '',
        ulol_openingsizeum: '',
        ulol_openingtype: '',
        ulol_screenangle: '',
        ulol_screenangleo: ''
    }

    public gritEquipmentInfoFormPayload: any = {
        ulol_chambertype: '',
        ulol_chamberdesign: '',
        ulol_upperchamberfloor: '',
        ulol_tankdesign: '',
        ulol_chamberdiameter: '',
        ulol_chamberdiameteruom: '',
        ulol_upperchamberdepth: '',
        ulol_upperchamberdepthuom: '',
        ulol_lowerchamberdepth: '',
        ulol_lowerchamberdepthuom: '',
        ulol_lowerchamberdiameter: '',
        ulol_lowerchamberdiameteruom: '',
        ulol_fantype: '',
        ulol_bridgeincluded: '',
        ulol_bridgemat: '',
        ulol_driveheadmaterial: '',
        ulol_cyclonerequired: '',
        ulol_pumpprice: '',
        ulol_pumptype: '',
        ulol_pumplocation: '',
        ulol_weirsupplied: '',
        ulol_bafflesupplied: '',
        ulol_gritcapturepercent: '',
        ulol_gritcapturesize: '',
        ulol_gritcapturetype: '',
        ulol_grittestingrequired: ''
    }

    public equipmentInfoFormPayload: any = {
        ulol_equip1qty: '',
        ulol_equip1type: '',
        ulol_equip1motor: '',
        ulol_equip1hp: '',
        ulol_screenmotorsp: '',
        ulol_screentype: '',
        ulol_equip2qty: '',
        ulol_equip2type: '',
        ulol_equip2motor: '',
        ulol_equip2hp: '',
        ulol_screwmotorsp: '',
        ulol_screwtype: '',
        ulol_driveloc: '',
        ulol_wiers: '',
        ulol_dischargeloc: '',
        ulol_projectnotes: '',
        is_existing: false,
        equipment_data: []
    }

    public equipmentDataModel = {
        qty: '',
        equip_type: '',
        equip_motor: '',
        equip_hp: '',
        screenmotorsp: '',
        screen_type: '',
        screw_type: ''
    }

    public bidInfoFormPayload: any = {
        ulol_bidhandler: '',
        ulol_addenda: '',
        ulol_addendaquantity: '',
        ulol_prebidmtg: '',
        ulol_prebidmtgdate: '',
        ulol_preapprovaldate: '',
        ulol_preapprovalday: ''
    }

    public controlsInfoFormPayload: any = {
        ulol_qtylcs: '',
        ulol_qtymainpanels: '',
        ulol_paneltype: '',
        ulol_screenstarter: '',
        ulol_compactorstarter: '',
        ulol_floatswitch: '',
        ulol_leveltransducer: '',
        ulol_ctlqtpriceest: ''
    }

    public pricingInfoFormPayload: any = {
        ulol_specified: '',
        ulol_named: '',
        ulol_bidbondreq: '',
        ulol_bidbondreqamt: '',
        ulol_projectsize: '',
        ulol_projectsizeo: '',
        ulol_perfbondreq: '',
        ulol_perfbondreqamtdur: '',
        ulol_pmtterms: '',
        ulol_pmttermso: '',
        ulol_witnessfactorytest: '',
        ulol_lengthqt: '',
        ulol_lengthqto: '',
        ulol_startuptrips: '',
        ulol_startupdays: '',
        ulol_motionswitch: '',
        ulol_motionswitchq: '',
        ulol_spareparts: '',
        ulol_sparepartso: '',
        ulol_baggingunit: '',
        ulol_baggingunito: '',
        ulol_anchorbolts: '',
        ulol_anchorboltso: '',
        ulol_solenoid: '',
        ulol_solenoido: '',
        ulol_strainer: '',
        ulol_strainero: '',
        ulol_heat: '',
        ulol_nozzlematerial: '',
        ulol_productionschedule: '',
        ulol_productionscheduleo: '',
        ulol_warranty: '',
        ulol_warrantyo: '',
        ulol_cathodicprotection: '',
        ulol_cathodicprotectspec: '',
        ulol_frgtcond: '',
        ulol_liquidateddamages: '',
        ulol_liquidateddamagesspec: '',
        ulol_sealedengdrw: '',
        ulol_sealedengdrwspec: '',
        ulol_pricingnotes: '',
        ulol_closed: false,
        rep_commission_level: '',
        salesperson_confidence: ''
    }

}