
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { finalize, forkJoin, of, switchMap } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { CommonDataModel } from 'src/app-core/data-model/common-data-model';
import { DeletedValueEnum } from 'src/app-core/enums/common-enum';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CustomPatterns } from 'src/app-core/pattern/custom-patterns';
import { ContactCompanyService } from 'src/app-core/services/contact-company.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { CommonSettings } from 'src/app-core/settings/common-settings';

@Component({
	selector: 'app-company-form',
	templateUrl: './company-form.component.html',
	styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent {

	customPatterns = new CustomPatterns();
	commonSettings = new CommonSettings();
	commonDataModel = new CommonDataModel();
	companyFormPayload = _.cloneDeep(this.commonDataModel.companyFormPayload);
	companyFormLoaderModel = _.cloneDeep(this.commonDataModel.companyFormLoaderModel);
	industryTypeSourceList: any;
	loggedUserData: any;
	confirmationResult: any;
	selectedContact: any;
	originalShippingAddresses: any;
	shippingAddress: any;
	shippingAddressNewData: any;
	updatedAddressNewData: any;
	existingId: any;
	companyListData: any;
	popoverContent: any;
	removedAssociations: any[] = [];
	contactList: any[] = [];
	updatedShippingAddresses: any[] = [];
	isActive = false;
	isRestore = false;
	isPopoverVisible = false;
	companyNameExist = false;
	formType: string = '';
	newContact!: {
		id: number;
		contact_id: number;
		contact_name: string;
		industry_type: string;
		is_removed: boolean;
	};
	contactIds: any[] = [];
	commonConstants: typeof CommonConstants = CommonConstants;

	constructor(
		private commonMethod: CommonMethod,
		private customToastrService: CustomToastrService,
		private companyService: ContactCompanyService,
		private dialogRef: MatDialogRef<CompanyFormComponent>,
		@Inject(MAT_DIALOG_DATA) data?: any) {
		if (data) {
			this.companyFormPayload.company_id = data?.companyId;
			this.companyFormPayload.id = data?.id;
			this.formType = data?.formType;
			this.onLoadItems();
		}
	}

	onLoadItems(): void {
		this.companyFormLoaderModel.isFormLoading = true;
		this.loggedUserData = this.commonMethod.getLoggedUserData();
		if (this.formType === CommonConstants.formTypeConstants.add) {
			this.companyFormPayload.created_by = this.loggedUserData.name;
			this.companyFormPayload.company_id = '';
			this.companyFormPayload.project_shipping_address[0] = '';
			forkJoin({
				companyId: this.companyService.getCompanyId(),
				contactList: this.companyService.getAllContactList(),
				industryTypes: this.companyService.getCompanyIndustryType()
			})
				.pipe(finalize(() => this.companyFormLoaderModel.isFormLoading = false))
				.subscribe(({ companyId, contactList, industryTypes }) => {
					this.companyFormPayload.company_id = companyId?.lastCompanyID;

					if (contactList?.data) {
						this.contactList = contactList.data.map((contact: any) => ({
							...contact,
							isDisabled: false
						}));
					}

					this.industryTypeSourceList = industryTypes.data.dd_fields.industry_type;
				});
		} else {
			this.companyService.getCompanyFormEdit(this.companyFormPayload.id)
				.pipe(
					switchMap(response => {
						if (response.data) {
							this.commonMethod.objectMapping(this.companyFormPayload, response.data);
							this.shippingAddress = this.companyFormPayload.project_shipping_address;

							if (this.companyFormPayload.project_shipping_address.length === 0) {
								this.companyFormPayload.project_shipping_address[0] = '';
							} else {
								this.originalShippingAddresses = response.data.project_shipping_address.map(
									(address: { address: any }) => address.address
								);
								this.companyFormPayload.project_shipping_address = this.originalShippingAddresses;
							}

							if (!this.companyFormPayload.created_by) {
								this.companyFormPayload.created_by = this.loggedUserData.name;
							}

							this.isRestore = (this.companyFormPayload.is_deleted === DeletedValueEnum.deletedRestoreValue);
							return forkJoin({
								contactList: this.companyService.getAllContactList(),
								industryTypes: this.companyService.getCompanyIndustryType()
							});
						}
						return of(null);
					}),
					finalize(() => this.companyFormLoaderModel.isFormLoading = false)
				)
				.subscribe(results => {
					if (results) {
						const { contactList, industryTypes } = results;

						if (contactList?.data) {
							this.contactList = contactList.data.map((contact: any) => ({
								...contact,
								isDisabled: false
							}));
							if (this.companyFormPayload?.association?.length) {
								this.companyFormPayload.association.forEach((association: { id: any }) => {
									const contact = this.contactList.find(c => c.id === association.id);
									if (contact) {
										contact.isDisabled = true;
									}
								});
							}
						}
						this.industryTypeSourceList = industryTypes.data.dd_fields.industry_type;
					}
				});
		}
	}

	async onShowArchiveConfirmation() {
		const confirmationMessage = this.isRestore
			? CommonConstants.appConstants.restoreCompanyConfirmationDialog
			: CommonConstants.appConstants.archiveCompanyConfirmationDialog;

		this.confirmationResult = await this.commonMethod.asyncOpenDeleteConformationDialog(`${confirmationMessage}`);

		if (this.confirmationResult) {
			this.postArchiveData();
		}
	}

	postArchiveData() {
		this.companyFormLoaderModel.isFormLoading = true;
		this.isActive = (this.companyFormPayload.is_deleted === DeletedValueEnum.deletedArchiveValue ? false : true);
		const payload = {
			company_id: this.companyFormPayload.id,
			is_active: this.isActive
		}
		this.companyService.getCompanyArchiveStatus(payload)
			.pipe(finalize(() => this.companyFormLoaderModel.isFormLoading = false))
			.subscribe(response => {
				this.isRestore = !this.isRestore;
				this.customToastrService.success(response?.message);
				this.dialogRef.close({ confirmResult: true });
			});
	}

	onShowCompanyByDomain() {
		if (this.companyFormPayload.domain) {
			this.companyFormLoaderModel.isFormLoading = true;
			const payload = {
				domain: this.companyFormPayload.domain,
			}
			this.companyService.getCompanyByDomain(payload)
				.pipe(finalize(() => this.companyFormLoaderModel.isFormLoading = false))
				.subscribe(response => {
					this.companyListData = response.data;
				})
		}
	}

	onNameFocus() {
		this.isPopoverVisible = true;
	}

	onNameFocusOut() {
		this.isPopoverVisible = false;
		const payload = {
			id: this.companyFormPayload.id ? this.companyFormPayload.id : null,
			name: this.companyFormPayload.name,
			domain: this.companyFormPayload.domain
		}
		this.companyService.checkCompanyName(payload)
			.subscribe(response => {
				this.companyNameExist = (response?.exist ? true : false);
			})
	}

	addShippingAddress() {
		if (!this.companyFormPayload.project_shipping_address) {
			this.companyFormPayload.project_shipping_address = [];
		} else if (this.companyFormPayload.project_shipping_address.length < 3) {
			this.companyFormPayload.project_shipping_address.push('');
		}
	}

	updateShippingAddress(index: number, address: string) {
		if (index >= 0 && index < this.shippingAddress.length && this.shippingAddress[index].id) {
			this.existingId = this.shippingAddress[index].id;
			if (this.existingId) {
				if (!this.updatedAddressNewData) {
					this.updatedAddressNewData = [];
				}
				this.updatedAddressNewData[index] = {
					id: this.existingId,
					address: address
				};
				this.companyFormPayload.shipping_address_changed = true;
			}
		} else if (index >= this.shippingAddress.length || !this.existingId) {
			if (!this.shippingAddressNewData) {
				this.shippingAddressNewData = [];
			}
			this.shippingAddressNewData[index] = address;
			this.companyFormPayload.shipping_address_changed = true;
		} else {
			this.companyFormPayload.shipping_address_changed = false;
		}
	}

	deleteShippingAddress(index: number) {
		if (this.companyFormPayload.project_shipping_address.length > 1 && this.formType == CommonConstants.formTypeConstants.add) {
			this.companyFormPayload.project_shipping_address.splice(index, 1);
		} else {
			const addressToDelete = this.shippingAddress[index];
			if (this.companyFormPayload.project_shipping_address.length > 1 && !addressToDelete) {
				this.companyFormPayload.project_shipping_address.splice(index, 1);
				this.shippingAddress.splice(index, 1);
			} else if (addressToDelete.id) {
				const payload = {
					id: addressToDelete.id,
				}
				this.companyFormLoaderModel.isFormLoading = true;
				this.companyService.deleteShippingAddress(payload)
					.pipe(finalize(() => this.companyFormLoaderModel.isFormLoading = false))
					.subscribe(response => {
						this.customToastrService.success(response?.message);
						this.companyFormPayload.project_shipping_address.splice(index, 1);
						this.shippingAddress.splice(index, 1);
					})
			}
		}
	}

	trackByFn(index: number, item: any): number {
		return index;
	}

	addContacts() {
		if (this.selectedContact) {
			const contact = this.contactList.find(c => c.id === this.selectedContact);
			if (contact) {
				this.newContact = {
					id: contact.id,
					contact_id: contact.contact_id,
					contact_name: contact.contact_name,
					industry_type: contact.industry_type,
					is_removed: false
				};

				this.companyFormPayload.association.push(this.newContact);
				this.companyFormPayload.association_changed = true;
				contact.isDisabled = true;

			}
			this.selectedContact = null;
		}
	}

	onDeleteAssociation(indexArray: number[]) {
		indexArray.sort((a, b) => b - a);
		const removedObjects: any[] = [];
		indexArray.forEach(index => {
			const association = this.companyFormPayload.association[index];
			if (Array.isArray(association)) {
				removedObjects.push(...association.filter(obj => typeof obj === 'object' && obj !== null));
				this.companyFormPayload.association.splice(index, 1);
			} else if (typeof association === 'object' && association !== null) {
				association.is_removed = true;
				removedObjects.push(association);
				this.companyFormPayload.association.splice(index, 1);
			}
		});

		removedObjects.forEach(removedObject => {
			const contact = this.contactList.find(c => c.contact_id === removedObject.contact_id);
			if (contact) {
				contact.isDisabled = false;
			}
		});

		this.removedAssociations.push(...removedObjects);
		this.companyFormPayload.association_changed = true;
	}

	onFormSubmit() {
		this.companyFormLoaderModel.isFormLoading = true;
		if (this.formType === CommonConstants.formTypeConstants.add) {
			if (this.newContact && this.removedAssociations.length > 0 && this.companyFormPayload.association.length === 0) {
				this.companyFormPayload.association_changed = false;
			}
			if (!this.newContact && this.companyFormPayload.association_changed) {
				const filteredRemovedAssociations = this.removedAssociations.filter(item => typeof item === 'object');
				this.companyFormPayload.association.push(...filteredRemovedAssociations);
			}
			const payloadToSend = { ...this.companyFormPayload };
			if (!payloadToSend.association_changed) {
				delete payloadToSend.association;
			}

			payloadToSend.project_shipping_address = payloadToSend.project_shipping_address
				.filter((address: string) => address && address.trim() !== "");
			if (payloadToSend.project_shipping_address.length === 0) {
				payloadToSend.project_shipping_address = null;
			}
			this.companyService.postCompanyForm(payloadToSend)
				.pipe(finalize(() => this.companyFormLoaderModel.isFormLoading = false))
				.subscribe(response => {
					this.customToastrService.success(response?.message);
					this.dialogRef.close({ confirmResult: true });
				});
		} else {
			if (this.newContact && this.removedAssociations.length > 0 && this.companyFormPayload.association.length === 0) {
				this.companyFormPayload.association_changed = false;
			}
			if (!this.newContact && this.companyFormPayload.association_changed) {
				const filteredRemovedAssociations = this.removedAssociations.filter(item => typeof item === 'object');
				this.companyFormPayload.association.push(...filteredRemovedAssociations);
			}
			const payloadToSend = { ...this.companyFormPayload };
			payloadToSend.project_shipping_address = [];
			if (!payloadToSend.association_changed) {
				delete payloadToSend.association;
			}

			if (this.updatedAddressNewData) {
				for (const entry of this.updatedAddressNewData) {
					if (entry && entry.id && entry.address) {
						payloadToSend.updated_shipping_address.push({
							id: entry.id,
							address: entry.address
						});
					}
				}
			}

			if (payloadToSend.updated_shipping_address.length === 0) {
				payloadToSend.updated_shipping_address = null;
			}

			if (this.shippingAddressNewData) {
				for (const entry of this.shippingAddressNewData) {
					if (entry) {
						payloadToSend.project_shipping_address.push(entry);
					}
				}
			}

			if (payloadToSend.project_shipping_address.length === 0) {
				payloadToSend.project_shipping_address = null;
			}

			if (!payloadToSend.project_shipping_address && !payloadToSend.updated_shipping_address) {
				this.companyFormPayload.shipping_address_changed = false;
			}

			this.companyService.updateCompanyForm(payloadToSend.id, payloadToSend)
				.pipe(finalize(() => this.companyFormLoaderModel.isFormLoading = false))
				.subscribe(response => {
					if (response?.isWarning) {
						this.customToastrService.warning(response?.message);
					} else {
						this.customToastrService.success(response?.message);
					}
					this.dialogRef.close({ confirmResult: true });
				});
		}
	}

	itemDisabled(itemArgs: { dataItem: any }) {
		return itemArgs.dataItem?.isDisabled;
	}

	onClose() {
		this.dialogRef.close({ confirmResult: false });
	}

}

