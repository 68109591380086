export class GridDataModel {

    public userRequestGridColumnModel: any = [
        {
            field: 'status',
            title: 'Status',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: null
        },
        {
            field: 'lead_id',
            title: 'Lead Id',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: null
        },
        {
            field: 'request_id',
            title: 'Request ID',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 190
        },
        {
            field: 'request_type',
            title: 'Type',
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: null
        },
        {
            field: 'required_at',
            title: 'Due Date',
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 170
        },
        {
            field: 'revised_due_date',
            title: 'Revised Due Date',
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 170
        },
        {
            field: "deal_name",
            title: "Project Name",
            hidden: true,
            sortable: true,
            orderIndex: 6,
            width: 170
        },
        {
            field: "deal_owner",
            title: "Lead Owner",
            hidden: true,
            sortable: true,
            orderIndex: 7,
            width: 170
        },
        {
            field: "industry_type",
            title: "Industry Type",
            hidden: true,
            sortable: true,
            orderIndex: 8,
            width: 170
        },
        {
            field: 'lead_line_part_id',
            title: 'Part Id',
            hidden: false,
            sortable: true,
            orderIndex: 9,
            width: 150
        },
        {
            field: 'lead_line_description',
            title: 'Lead Description',
            hidden: false,
            sortable: true,
            orderIndex: 10,
            width: 170
        },
        {
            field: 'action',
            title: 'Action',
            hidden: false,
            sortable: false,
            orderIndex: 11,
            width: 150
        }
    ];

    public leadGridColumnModel: any = [
        {
            field: "lead_id",
            title: "Lead ID",
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: "dealname",
            title: "Lead Name",
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 200
        },
        {
            field: "long_description",
            title: "Description",
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 250
        },
        {
            field: "legacy_id",
            title: "Legacy ID",
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: 180
        },
        {
            field: "status",
            title: "Lead Status",
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: "leadline_count",
            title: "LeadLine Count",
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 150
        },
        {
            field: "lead_created_date",
            title: "Lead Date",
            hidden: false,
            sortable: true,
            orderIndex: 6,
            width: 100
        },
        {
            field: "deal_owner",
            title: "Lead Owner",
            hidden: false,
            sortable: true,
            orderIndex: 7,
            width: 150
        },
        {
            field: "deal_stage",
            title: "Lead Stage",
            hidden: true,
            sortable: true,
            orderIndex: 8,
            width: 150
        },
        {
            field: "closed_reason",
            title: "Closed Reason",
            hidden: false,
            sortable: true,
            orderIndex: 9,
            width: 150
        },
        {
            field: "closed_by",
            title: "Closed By",
            hidden: false,
            sortable: true,
            orderIndex: 10,
            width: 150
        },
        {
            field: "close_date",
            title: "Closed Date",
            hidden: false,
            sortable: true,
            orderIndex: 11,
            width: 190
        },
        {
            field: "max_score",
            title: "Score",
            hidden: false,
            sortable: true,
            orderIndex: 12,
            width: 150
        },
        {
            field: "deal_city",
            title: "City",
            hidden: true,
            sortable: true,
            orderIndex: 13,
            width: 150
        },
        {
            field: "deal_state",
            title: "State",
            hidden: true,
            sortable: true,
            orderIndex: 14,
            width: 150
        }
    ];

    public leadlineGridColumnModel: any = [
        {
            field: 'lolleadline_id',
            title: 'Leadline ID',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'is_active',
            title: 'Status',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'lolcreated_date',
            title: 'Date Created',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 190
        },
        {
            field: 'loldescription',
            title: 'Description',
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: 150
        },
        {
            field: 'lolpart_id',
            title: 'Part ID',
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: 'leadline_amount',
            title: 'Leadline Amount',
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 180
        },
        {
            field: 'score',
            title: 'Score',
            hidden: false,
            sortable: true,
            orderIndex: 6,
            width: 150
        }
    ];

    public issuesGridColumnModel: any = [
        {
            field: 'id',
            title: 'Issue ID',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'leadline_id',
            title: 'Lead',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'subject',
            title: 'Issue Subject',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 150
        },
        {
            field: 'created_at',
            title: 'Created At',
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: 190
        },
        {
            field: 'creater',
            title: 'RSM',
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: 'responder',
            title: 'Responder',
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 150
        },
        {
            field: 'due_date_time',
            title: 'Due At',
            hidden: true,
            sortable: true,
            orderIndex: 6,
            width: 190
        },
        {
            field: 'revised_due_date',
            title: 'Revised Due Date',
            hidden: true,
            sortable: true,
            orderIndex: 7,
            width: 190
        },
        {
            field: 're_assigned_to_name',
            title: 'Re-Assigned To',
            hidden: true,
            sortable: true,
            orderIndex: 8,
            width: 150
        },
        {
            field: 'file_name',
            title: 'File',
            hidden: true,
            sortable: false,
            orderIndex: 9,
            width: 150
        },
        {
            field: 'status',
            title: 'Status',
            hidden: true,
            sortable: true,
            orderIndex: 10,
            width: 150
        },
        {
            field: 'action',
            title: 'Action',
            hidden: false,
            sortable: false,
            orderIndex: 11,
            width: 150
        }
    ];

    public sizingGridColumnModel: any = [
        {
            field: 'doc_req_id',
            title: 'Request ID',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'model',
            title: 'Screen Type/Model Number',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 150
        }
    ];

    public pricingGridColumnModel: any = [
        {
            field: 'doc_req_id',
            title: 'Request ID',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'price',
            title: 'Price',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 150
        }
    ];

    public leadCommonGridColumnModel: any = [
        {
            field: 'responders',
            title: 'Assigned To',
            hidden: false,
            sortable: false,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'file',
            title: 'File',
            hidden: false,
            sortable: false,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'support_documents',
            title: 'Supporting Documents',
            hidden: false,
            sortable: false,
            orderIndex: 2,
            width: 200
        },
        {
            field: 'comment',
            title: 'Comments',
            hidden: true,
            sortable: false,
            orderIndex: 3,
            width: 150
        },
        {
            field: 'requested_by_name',
            title: 'Requested By',
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: 'created_at',
            title: 'Requested At',
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 150
        },
        {
            field: 'created_by_name',
            title: 'RSM',
            hidden: false,
            sortable: true,
            orderIndex: 6,
            width: 150
        },
        {
            field: 'uploaded_at',
            title: 'Created At',
            hidden: false,
            sortable: true,
            orderIndex: 7,
            width: 200
        },
        {
            field: 'required_at',
            title: 'Due By',
            hidden: false,
            sortable: true,
            orderIndex: 8,
            width: 200
        },
        {
            field: 're_assigned_to_name',
            title: 'Re-Assigned To',
            hidden: false,
            sortable: true,
            orderIndex: 9,
            width: 150
        },
        {
            field: 'revised_due_date',
            title: 'Revised Due Date',
            hidden: false,
            sortable: true,
            orderIndex: 10,
            width: 200
        },
        {
            field: 'approved_by_name',
            title: 'Approver',
            hidden: false,
            sortable: false,
            orderIndex: 11,
            width: 150
        },
        {
            field: 'approved_at',
            title: 'Approved At',
            hidden: false,
            sortable: true,
            orderIndex: 12,
            width: 200
        },
        {
            field: 'cancelled_by_name',
            title: 'Cancelled By',
            hidden: false,
            sortable: true,
            orderIndex: 13,
            width: 150
        },
        {
            field: 'cancelled_at',
            title: 'Cancelled At',
            hidden: false,
            sortable: true,
            orderIndex: 14,
            width: 150
        },
        {
            field: 'is_approved',
            title: 'Status',
            hidden: false,
            sortable: true,
            orderIndex: 15,
            width: 150
        },
        {
            field: 'action',
            title: 'Action',
            hidden: false,
            sortable: false,
            orderIndex: 16,
            width: 220
        }
    ];

    public changeLogGridColumnModel: any = [
        {
            field: 'change_id',
            title: 'Change ID',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'change_type',
            title: 'Change Type',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'change_at',
            title: 'Changed At',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 150
        },
        {
            field: 'changed_by',
            title: 'Changed By',
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: 150
        },
        {
            field: 'changed_table',
            title: 'Changed Table',
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: 'changed_key',
            title: 'Changed Key',
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 150
        },
        {
            field: 'old_table',
            title: 'Old Value',
            hidden: false,
            sortable: true,
            orderIndex: 6,
            width: 150
        },
        {
            field: 'new_value',
            title: 'New Value',
            hidden: false,
            sortable: true,
            orderIndex: 7,
            width: 150
        }
    ];

    public userGridColumnModel: any = [
        {
            field: 'unique_user_id',
            title: 'User ID',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'name',
            title: 'Name',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'role',
            title: 'Role',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 150
        },
        {
            field: 'group',
            title: 'Group',
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: 150
        },
        {
            field: 'email',
            title: 'Email ID',
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: 'notifications',
            title: 'Notifications',
            hidden: false,
            sortable: false,
            orderIndex: 5,
            width: 150
        },
        {
            field: 'action',
            title: 'Action',
            hidden: false,
            sortable: false,
            orderIndex: 6,
            width: 150
        }
    ];

    public performanceMetricsGridColumnModel: any = [
        {
            field: 'status',
            title: 'Status',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'leadline_id',
            title: 'Lead ID',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'request_id',
            title: 'Request ID',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 150
        },
        {
            field: 'request_type',
            title: 'Type',
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: 150
        },
        {
            field: 'responders',
            title: 'Assigned To',
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: 'last_action_at',
            title: 'Last Action',
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 150
        },
        {
            field: 'responded_by',
            title: 'First Response',
            hidden: false,
            sortable: true,
            orderIndex: 6,
            width: 150
        },
        {
            field: 'responded_at',
            title: 'First Response Time',
            hidden: false,
            sortable: true,
            orderIndex: 7,
            width: 150
        },
        {
            field: 'requested_by',
            title: 'Requested By',
            hidden: false,
            sortable: true,
            orderIndex: 8,
            width: 150
        },
        {
            field: 'requested_at',
            title: 'Requested At',
            hidden: false,
            sortable: true,
            orderIndex: 9,
            width: 150
        },
        {
            field: 'required_at',
            title: 'Due Date',
            hidden: false,
            sortable: true,
            orderIndex: 10,
            width: 150
        },
        {
            field: 're_assigned_to_name',
            title: 'Re-Assigned To',
            hidden: false,
            sortable: true,
            orderIndex: 11,
            width: 150
        },
        {
            field: 'revised_due_date',
            title: 'Revised Due Date',
            hidden: false,
            sortable: true,
            orderIndex: 12,
            width: 150
        },
        {
            field: 'rejected_by',
            title: 'Declined By',
            hidden: false,
            sortable: true,
            orderIndex: 13,
            width: 150
        },
        {
            field: 'rejected_at',
            title: 'Declined At',
            hidden: false,
            sortable: true,
            orderIndex: 14,
            width: 150
        },
        {
            field: 'date_completed',
            title: 'Date Completed',
            hidden: false,
            sortable: true,
            orderIndex: 15,
            width: 150
        },
        {
            field: 'is_approved',
            title: 'Document Status',
            hidden: false,
            sortable: true,
            orderIndex: 16,
            width: 150
        },
        {
            field: 'response_time',
            title: 'Response Time',
            hidden: false,
            sortable: true,
            orderIndex: 17,
            width: 150
        }
    ];

    public reportsGridColumnModel: any = [
        {
            field: "lead_id",
            title: "Lead ID",
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'deal_name',
            title: 'Lead Name',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 200
        },
        {
            field: 'lolleadline_id',
            title: 'Leadline ID',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 150
        },
        {
            field: 'is_active',
            title: 'Leadline Status',
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: 150
        },
        {
            field: "bid_date",
            title: "Bid Date",
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: "description",
            title: "Description",
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 150
        },
        {
            field: "long_description",
            title: "Long Description",
            hidden: false,
            sortable: true,
            orderIndex: 6,
            width: 180
        },
        {
            field: "amount",
            title: "Lead Amount",
            hidden: false,
            sortable: true,
            orderIndex: 7,
            width: 200
        },
        {
            field: "lead_created_date",
            title: "Lead Date",
            hidden: false,
            sortable: true,
            orderIndex: 8,
            width: 150
        },
        {
            field: "created_by",
            title: "RSM",
            hidden: false,
            sortable: true,
            orderIndex: 9,
            width: 180
        },
        {
            field: "closed_by",
            title: "Closed By",
            hidden: false,
            sortable: true,
            orderIndex: 10,
            width: 150
        },
        {
            field: "rep_commission_level",
            title: "Rep Commission Level",
            hidden: false,
            sortable: true,
            orderIndex: 11,
            width: 200
        },
        {
            field: "salesperson_confidence",
            title: "Sales Person Confidence",
            hidden: false,
            sortable: true,
            orderIndex: 12,
            width: 210
        },
        {
            field: 'leadline_amount',
            title: 'Leadline Amount',
            hidden: false,
            sortable: true,
            orderIndex: 13,
            width: 180
        },
        {
            field: 'business_development',
            title: 'Business Development',
            hidden: false,
            sortable: true,
            orderIndex: 14,
            width: 200
        },
        {
            field: 'referred_by',
            title: 'Referred By',
            hidden: false,
            sortable: true,
            orderIndex: 15,
            width: 150
        },
        {
            field: 'status',
            title: 'Status',
            hidden: false,
            sortable: true,
            orderIndex: 16,
            width: 150
        },
        {
            field: 'closedate',
            title: 'Closed Date',
            hidden: false,
            sortable: true,
            orderIndex: 17,
            width: 150
        },
        {
            field: 'closed_reason',
            title: 'Closed Reason',
            hidden: false,
            sortable: true,
            orderIndex: 18,
            width: 150
        },
        {
            field: 'marketing_program',
            title: 'Marketing Program',
            hidden: false,
            sortable: true,
            orderIndex: 19,
            width: 200
        },
        {
            field: "equipment_type",
            title: "Equipments",
            hidden: false,
            sortable: false,
            orderIndex: 20,
            width: 150
        },
        {
            field: "city",
            title: "City",
            hidden: false,
            sortable: false,
            orderIndex: 21,
            width: 150
        },
        {
            field: "state",
            title: "State",
            hidden: false,
            sortable: false,
            orderIndex: 22,
            width: 150
        },
        {
            field: "country",
            title: "Country",
            hidden: false,
            sortable: false,
            orderIndex: 23,
            width: 150
        },
        {
            field: 'expected_close_date',
            title: 'Expected Close Date',
            hidden: false,
            sortable: true,
            orderIndex: 24,
            width: 250
        },
        {
            field: 'deal_stage_date',
            title: 'Lead Stage Date',
            hidden: false,
            sortable: true,
            orderIndex: 25,
            width: 250
        },
        {
            field: 'deal_stage',
            title: 'Lead Stage',
            hidden: false,
            sortable: true,
            orderIndex: 26,
            width: 250
        },
        {
            field: 'company',
            title: 'Primary Company',
            hidden: false,
            sortable: false,
            orderIndex: 27,
            width: 250
        },
        {
            field: 'prospect_tracking_count',
            title: 'Prospect Tracking Notes',
            hidden: false,
            sortable: true,
            orderIndex: 28,
            width: 200
        },
        {
            field: 'rep_company',
            title: 'Rep Company',
            hidden: false,
            sortable: true,
            orderIndex: 29,
            width: 200
        },
    ];

    public serviceGridColumnModel: any = [
        {
            field: 'kbpCallID',
            title: 'Service ID',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'kbpCallTypeID',
            title: 'Request Type',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'kbpOrganizationID',
            title: 'Company ID',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 200
        },
        {
            field: 'kbpAffiliateCompanyName',
            title: 'Primary Company',
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: 300
        },
        {
            field: 'kbpOpenedDate',
            title: 'Created On',
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: 'kbpAssignedToEmployeeID',
            title: 'Contact Name',
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 200
        },
        {
            field: 'kbpPhoneNumber',
            title: 'Contact Number',
            hidden: false,
            sortable: true,
            orderIndex: 6,
            width: 200
        },
        {
            field: 'kbpShortDescription',
            title: 'Short Description',
            hidden: false,
            sortable: true,
            orderIndex: 7,
            width: 250
        },
        {
            field: 'kbpStatus',
            title: 'Request Status',
            hidden: false,
            sortable: true,
            orderIndex: 8,
            width: 150
        },
        {
            field: 'kbpOpenedByEmployeeID',
            title: 'Request Creator',
            hidden: false,
            sortable: true,
            orderIndex: 9,
            width: 150
        },
        {
            field: 'call_time',
            title: 'Request Time',
            hidden: false,
            sortable: true,
            orderIndex: 10,
            width: 150
        },
        {
            field: 'kbpDueDate',
            title: 'Follow Up Time',
            hidden: false,
            sortable: true,
            orderIndex: 11,
            width: 150
        },
        {
            field: 'action',
            title: 'Action',
            hidden: false,
            sortable: false,
            orderIndex: 12,
            width: 200
        }
    ];

    public serviceNestedGridColumnModel: any = [
        {
            field: 'callline_id',
            title: 'Service ID',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 100
        },
        {
            field: 'call_type',
            title: 'Request Type',
            hidden: false,
            sortable: false,
            orderIndex: 1,
            width: 120
        },
        {
            field: 'kblOrganizationID',
            title: 'Company ID',
            hidden: false,
            sortable: false,
            orderIndex: 2,
            width: 150
        },
        {
            field: 'kbpAffiliateCompanyName',
            title: 'Primary Company',
            hidden: false,
            sortable: false,
            orderIndex: 3,
            width: 150
        },
        {
            field: 'kblContactMethodID',
            title: 'Contact',
            hidden: false,
            sortable: false,
            orderIndex: 4,
            width: 120
        },
        {
            field: 'kblPhoneNumber',
            title: 'Contact Number',
            hidden: false,
            sortable: false,
            orderIndex: 5,
            width: 150
        },
        {
            field: 'kblShortDescription',
            title: 'Short Description',
            hidden: false,
            sortable: true,
            orderIndex: 6,
            width: 150
        },
        {
            field: 'status',
            title: 'Request Status',
            hidden: false,
            sortable: false,
            orderIndex: 7,
            width: 120
        },
        {
            field: 'kblCreatedBy',
            title: 'Request Creator',
            hidden: false,
            sortable: true,
            orderIndex: 8,
            width: 150
        },
        {
            field: 'call_time',
            title: 'Request Time',
            hidden: false,
            sortable: true,
            orderIndex: 9,
            width: 150
        },
        {
            field: 'kblDueDate',
            title: 'Follow Up Time',
            hidden: false,
            sortable: false,
            orderIndex: 10,
            width: 150
        },
        {
            field: 'action',
            title: 'Action',
            hidden: false,
            sortable: false,
            orderIndex: 11,
            width: 150
        }
    ];
    public affiliatesListColumnModel: any = [
        {
            field: 'name',
            title: 'Name',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 120
        },
        {
            field: 'industry_type',
            title: 'Industry Type',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 120
        },
        {
            field: 'company_name',
            title: 'Company Name',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 200
        }
    ];

    public serviceReportGridColumnModel: any = [
        {
            field: "kbpCallID",
            title: "Service ID",
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: "kbpLeadID",
            title: "Lead ID",
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'kbpCallTypeID',
            title: 'Service Request Type',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 200
        },
        {
            field: 'kbpOrganizationID',
            title: 'Primary Company',
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: 200
        },
        {
            field: "kbpLeadlineID",
            title: "Leadline",
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: "kbpShortDescription",
            title: "Short Description",
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 180
        },
        {
            field: "kbpLongDescriptionText",
            title: "Long Description",
            hidden: false,
            sortable: true,
            orderIndex: 6,
            width: 180
        },
        {
            field: "kbpStatus",
            title: "Service Request Status",
            hidden: false,
            sortable: true,
            orderIndex: 7,
            width: 200
        },
        {
            field: "kbpOpenedByEmployeeID",
            title: "Service Request By RSM",
            hidden: false,
            sortable: true,
            orderIndex: 8,
            width: 250
        },
        {
            field: "call_time",
            title: "Service Request Time",
            hidden: false,
            sortable: true,
            orderIndex: 9,
            width: 250
        },
        {
            field: "kbpDueDate",
            title: "Follow Up Time",
            hidden: false,
            sortable: true,
            orderIndex: 10,
            width: 250
        },
        {
            field: "kbpOpenedDate",
            title: "Service Request Created Time",
            hidden: false,
            sortable: true,
            orderIndex: 11,
            width: 250
        },
        {
            field: "startup_date",
            title: "Startup Date",
            hidden: false,
            sortable: true,
            orderIndex: 12,
            width: 250
        },
        {
            field: "startup_tech",
            title: "Startup Tech",
            hidden: false,
            sortable: true,
            orderIndex: 13,
            width: 250
        },
        {
            field: "serial_numbers",
            title: "Serial Numbers",
            hidden: false,
            sortable: true,
            orderIndex: 14,
            width: 250
        },

    ];

    public companyGridColumnModel: any = [
        {
            field: 'company_id',
            title: 'Company ID',
            hidden: true,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'domain',
            title: 'Domain Name',
            hidden: true,
            sortable: true,
            orderIndex: 1,
            width: 200
        },
        {
            field: 'name',
            title: 'Company Name',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 300
        },
        {
            field: 'address',
            title: 'Street Address',
            hidden: true,
            sortable: true,
            orderIndex: 3,
            width: 150
        },
        {
            field: 'city',
            title: 'City',
            hidden: false,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: 'state',
            title: 'State/ Region',
            hidden: false,
            sortable: true,
            orderIndex: 5,
            width: 200
        },
        {
            field: 'zip',
            title: 'Postal Code',
            hidden: false,
            sortable: false,
            orderIndex: 6,
            width: 200
        },
        {
            field: 'country',
            title: 'Country/ Region',
            hidden: true,
            sortable: true,
            orderIndex: 7,
            width: 200
        },
        {
            field: 'phone',
            title: 'Phone Number',
            hidden: true,
            sortable: false,
            orderIndex: 8,
            width: 200
        },
        {
            field: 'alt_phone_number',
            title: 'Alt Phone Number',
            hidden: true,
            sortable: false,
            orderIndex: 9,
            width: 250
        },
        {
            field: 'fax_number',
            title: 'Fax Number',
            hidden: true,
            sortable: false,
            orderIndex: 10,
            width: 150
        },
        {
            field: 'email_address',
            title: 'Email Address',
            hidden: true,
            sortable: true,
            orderIndex: 11,
            width: 150
        },
        {
            field: 'industry_type',
            title: 'Industry Type',
            hidden: true,
            sortable: true,
            orderIndex: 12,
            width: 200
        },
        {
            field: 'description',
            title: 'Description',
            hidden: true,
            sortable: false,
            orderIndex: 13,
            width: 200
        }
    ];

    public contactsGridColumnModel: any = [
        {
            field: 'contact_id',
            title: 'Contact ID',
            hidden: true,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'firstname',
            title: 'First Name',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 200
        },
        {
            field: 'lastname',
            title: 'Last Name',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 200
        },
        {
            field: 'email',
            title: 'Email',
            hidden: true,
            sortable: true,
            orderIndex: 3,
            width: 250
        },
        {
            field: 'jobtitle',
            title: 'Job Title',
            hidden: true,
            sortable: true,
            orderIndex: 4,
            width: 150
        },
        {
            field: 'industry_type',
            title: 'Industry Type',
            hidden: true,
            sortable: true,
            orderIndex: 5,
            width: 200
        },
        {
            field: 'company_name',
            title: 'Company',
            hidden: false,
            sortable: true,
            orderIndex: 6,
            width: 150
        },
        {
            field: 'company_state',
            title: 'State',
            hidden: false,
            sortable: true,
            orderIndex: 7,
            width: 200
        },
        {
            field: 'company_city',
            title: 'City',
            hidden: false,
            sortable: true,
            orderIndex: 8,
            width: 150
        },
        {
            field: 'phone',
            title: 'Phone Number',
            hidden: true,
            sortable: false,
            orderIndex: 9,
            width: 200
        },
        {
            field: 'alt_phone_number',
            title: 'Alt Phone Number',
            hidden: true,
            sortable: false,
            orderIndex: 10,
            width: 250
        },
        {
            field: 'mobilephone',
            title: 'Mobile Number',
            hidden: true,
            sortable: false,
            orderIndex: 11,
            width: 150
        },
        {
            field: 'fax',
            title: 'Fax Number',
            hidden: true,
            sortable: false,
            orderIndex: 12,
            width: 150
        },
        {
            field: 'notes',
            title: 'Notes',
            hidden: true,
            sortable: false,
            orderIndex: 13,
            width: 150
        }
    ];

    public associatedContactsGridColumnModel: any = [
        {
            field: 'id',
            title: 'Lead ID',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'dealname',
            title: 'Project Name',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 200
        },
    ];

    public associatedCompanyGridColumnModel: any = [
        {
            field: 'id',
            title: 'Lead ID',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'dealname',
            title: 'Project Name',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 200
        },
    ];

    public announcementsGridColumnModel: any = [
        {
            field: 'updated_at',
            title: 'Date',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 200
        },
        {
            field: 'subject_line',
            title: 'Title',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 150
        },
        {
            field: 'created_by',
            title: 'Created By',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 200
        },
        {
            field: 'action',
            title: 'Action',
            hidden: false,
            sortable: false,
            orderIndex: 3,
            width: 200
        }
    ];

    public logHistoryGridColumnModel: any = [
        {
            field: 'user_id',
            title: 'Name',
            hidden: false,
            sortable: true,
            orderIndex: 0,
            width: 150
        },
        {
            field: 'logged_in_at',
            title: 'Log in',
            hidden: false,
            sortable: true,
            orderIndex: 1,
            width: 200
        },
        {
            field: 'logged_out_at',
            title: 'Log out',
            hidden: false,
            sortable: true,
            orderIndex: 2,
            width: 300
        },
        {
            field: 'duration',
            title: 'Session Duration (Hr)',
            hidden: false,
            sortable: true,
            orderIndex: 3,
            width: 150
        }
    ];

}