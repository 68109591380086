import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CommonConstants } from 'src/app-core/constants/common-constants';
import { LeadDataModel } from 'src/app-core/data-model/lead-data-model';
import { LeadLineDataModel } from 'src/app-core/data-model/leadline-data-model';
import { CommonMethod } from 'src/app-core/method/common-method';
import { CommonService } from 'src/app-core/services/common.service';
import { CustomToastrService } from 'src/app-core/services/custom-toastr-service';
import { LeadLineService } from 'src/app-core/services/lead-line.service';
import { LeadModuleService } from 'src/app-core/services/lead-module.service';

@Component({
    selector: 'app-lead-layout',
    templateUrl: './lead-layout.component.html',
    styleUrls: ['./lead-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LeadLayoutComponent implements OnInit, OnDestroy {

    componentSubscriptions: Subscription = new Subscription();
    LeadDataModel = new LeadDataModel();
    leadLineDataModel = new LeadLineDataModel();
    leadSideNavBarModel = _.cloneDeep(this.LeadDataModel.leadSideNavBarModel);
    leadDetailsHeaderModel = _.cloneDeep(this.leadLineDataModel.leadDetailsHeaderModel);;
    paramLeadId: any;
    paramLeadLineId: any;
    paramRequestId: any;
    commonConstants: typeof CommonConstants = CommonConstants;

    constructor(
        private router: Router,
        private commonMethod: CommonMethod,
        private activeRoute: ActivatedRoute,
        private commonService: CommonService,
        private leadLineService: LeadLineService,
        private leadModuleService: LeadModuleService,
        private customToastrService: CustomToastrService) {
    }

    ngOnInit(): void {
        this.componentSubscriptions.add(this.activeRoute.paramMap.subscribe(params => {
            this.paramLeadId = params.get('leadId');
            this.paramLeadLineId = params.get('leadlineId');
            this.paramRequestId = params.get('requestId');
            this.commonMethod.setLeadParamsToServices(this.paramLeadId, this.paramLeadLineId, this.paramRequestId);
            if (this.commonMethod.checkLeadLineId(this.paramLeadLineId)) {
                this.onLoadItems();
            }
        }));
        this.componentSubscriptions.add(this.leadLineService.getLeadLineHeader.subscribe((data: any) => {
            if (!_.isEmpty(data)) {
                if (data?.lolpart_id) {
                    this.leadDetailsHeaderModel.part_id = data?.lolpart_id;
                }
                if (data?.lolquantity) {
                    this.leadDetailsHeaderModel.quantity = data?.lolquantity;
                }
                if (data?.leadline_amount) {
                    this.leadDetailsHeaderModel.projected_revenue = data?.leadline_amount;
                }
            }
        }));
        this.componentSubscriptions.add(this.leadLineService.getLeadLineScore.subscribe((data: boolean) => {
            if (data) {
                this.onGetScore();
            }
        }));
        this.componentSubscriptions.add(this.commonMethod.getLoadFieldCheck.subscribe((data: boolean) => {
            if (data) {
                this.onGetFieldsCheck();
                this.commonMethod.sendLoadFieldCheck(false);
            }
        }));
        this.componentSubscriptions.add(this.commonMethod.getLoadLeadLineDetailsRecords.subscribe((data: boolean) => {
            if (data) {
                this.onLoadItems();
                this.commonMethod.sendLoadLeadLineDetailsRecords(false);
            }
        }));
    }

    onLoadItems(): void {
        this.commonService.getLeadLineDetails(this.paramLeadId, this.paramLeadLineId).subscribe(response => {
            if (response.data) {
                this.commonMethod.sendIsActiveLeadlineStatus(!response.data?.is_active);
                this.commonMethod.objectMapping(this.leadDetailsHeaderModel, response.data);
                this.onGetFieldsCheck();
            }
        });
    }

    onGetFieldsCheck(): void {
        this.commonService.getFieldsCheck().subscribe(response => {
            if (response.data) {
                const data = _.cloneDeep(response.data);
                _.filter(this.leadSideNavBarModel, (list => {
                    if (list.name === CommonConstants.gridNameConstants.sizing) {
                        list.isCompleted = data?.sizing?.is_completed;
                    }
                    if (list.name === CommonConstants.gridNameConstants.drawing) {
                        list.isCompleted = data?.drawing?.is_completed;
                    }
                    if (list.name === CommonConstants.gridNameConstants.pricing) {
                        list.isCompleted = data?.pricing?.is_completed;
                    }
                    if (list.name === CommonConstants.gridNameConstants.engineerReport) {
                        list.isCompleted = data?.isReportGenerated;
                    }
                }));
            }
        });
    }

    onGetScore(): void {
        this.leadModuleService.getLeadLineScore(this.paramLeadId, this.paramLeadLineId).subscribe(response => {
            if (response.data) {
                this.leadDetailsHeaderModel.score = response.data;
            }
        });
    }

    onCheckCurrentRoute() {
        if (this.router.url.includes('sizing-list') ||
            this.router.url.includes('drawing-list') ||
            this.router.url.includes('pricing-list')) {
            return true;
        }
        return false;
    }

    onValidateNavigation(): void {
        this.onLoadItems();
    }

    ngOnDestroy(): void {
        this.componentSubscriptions.unsubscribe();
    }

}
