import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonMethod } from 'src/app-core/method/common-method';

@Component({
    selector: 'app-lead-details-info',
    templateUrl: './lead-details-info.component.html'
})
export class LeadDetailsInfoComponent implements OnInit {

    componentSubscriptions: Subscription = new Subscription();
    leadDetailsModel: any;

    @Input() set data(list: any) {
        this.leadDetailsModel = list;
    }

    constructor(
        private commonMethod: CommonMethod
    ) { }

    ngOnInit(): void {
        this.componentSubscriptions.add(
            this.commonMethod.getRevenueValue.subscribe((data: any) => {
                if (data && this.leadDetailsModel) {
                    this.leadDetailsModel.amount = data;
                }
            })
        );
    }

}
