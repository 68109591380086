<h1 mat-dialog-title>
    <span>
        {{formType === commonConstants.formTypeConstants.add ? 'Create': 'Edit'}} Company
    </span>
    <button mat-icon-button type="button" matTooltip="Close" (click)="onClose()">
        <img src="assets/images/close-icon.svg" />
    </button>
</h1>
<div class="form-dialog-wrapper">
    <div class="overlay-loader" [busyIf]="companyFormLoaderModel.isFormLoading">
        <div class="form-loader"></div>
    </div>
    <form #companyForm="ngForm" novalidate autocomplete="off" (ngSubmit)="companyForm.form.valid && onFormSubmit()">
        <div class="dialog-content-wrapper row">
            <div class="col-6 d-none">
                <div class="form-field mb-2">
                    <div class="label-text">Company ID <sup>*</sup></div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.company_id" name="company_id" autocomplete="off"
                        [disabled]="true" required>
                    </kendo-textbox>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Domain</div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.domain" name="domain" autocomplete="off"
                        [maxlength]="250" [pattern]="customPatterns.domainPattern" (focusout)="onShowCompanyByDomain()">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.domain?.length || 0}}/250
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Name <sup>*</sup></div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.name" name="name" autocomplete="off" [maxlength]="50"
                        (focus)="onNameFocus()" (focusout)="onNameFocusOut()" required>
                    </kendo-textbox>
                    <small class="error-text" *ngIf="companyNameExist">Company Name already exist!</small>
                    <div class="popover position-absolute" role="tooltip"
                        *ngIf="isPopoverVisible && companyListData && companyListData.length > 0">
                        <div class="popover-arrow"></div>
                        <div class="popover-header">
                            <p class="title">
                                {{companyFormPayload.domain}}
                            </p>
                            <span class="sub-title">
                                The entered domain name already exists on the application with the following company
                                names.
                            </span>
                        </div>
                        <div class="popover-body">
                            <ul>
                                <li *ngFor="let companies of companyListData">
                                    {{ companies.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.name?.length || 0}}/50
                    </mat-hint>
                </div>
            </div>
            <div class="col-12">
                <div class="form-field mb-2">
                    <div class="label-text">Email Address</div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.email_address" name="email_address"
                        autocomplete="off" [maxlength]="250" [pattern]="customPatterns.email">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.email_address?.length || 0}}/250
                    </mat-hint>
                </div>
            </div>
            <div class="col-12">
                <div class="form-field mb-2">
                    <div class="label-text">Address</div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.address" name="address" [maxlength]="500"
                        autocomplete="off">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.address?.length || 0}}/500
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">City <sup>*</sup></div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.city" name="city" autocomplete="off" [maxlength]="50"
                        required>
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.city?.length || 0}}/50
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">State <sup>*</sup></div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.state" name="state" autocomplete="off"
                        [maxlength]="250" required>
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.state?.length || 0}}/250
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Zip</div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.zip" name="zip" autocomplete="off" [maxlength]="25">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.zip?.length || 0}}/25
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Country</div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.country" name="country" autocomplete="off"
                        [maxlength]="50">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.country?.length || 0}}/50
                    </mat-hint>
                </div>
            </div>
            <div class="col-12" *ngIf="formType === commonConstants.formTypeConstants.add else editShippingAddress">
                <div class="form-field mb-2">
                    <div class="label-text">Project Shipping Address 1</div>
                    <div
                        [ngClass]="{'d-flex align-items-center': companyFormPayload.project_shipping_address?.length === 1, 'w-100': companyFormPayload.project_shipping_address?.length !== 1}">
                        <kendo-textbox [(ngModel)]="companyFormPayload.project_shipping_address[0]"
                            name="project_shipping_address_1" autocomplete="off">
                        </kendo-textbox>
                        <div *ngIf="companyFormPayload.project_shipping_address.length === 1">
                            <span class="icon-button-wrapper">
                                <button mat-flat-button type="button" color="input-color"
                                    matTooltip="Add Shipping Address" (click)="addShippingAddress()">
                                    <img src="assets/images/add-circle-icon.svg" height="20" />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngFor="let address of companyFormPayload.project_shipping_address.slice(1); let i = index; trackBy: trackByFn"
                    class="form-field mb-2">
                    <div class="label-text">Project Shipping Address {{i + 2}}</div>
                    <div
                        [ngClass]="{'d-flex align-items-center': true, 'w-100': companyFormPayload.project_shipping_address?.length < 3 && i + 1 !== companyFormPayload.project_shipping_address?.length - 1}">
                        <kendo-textbox [(ngModel)]="companyFormPayload.project_shipping_address[i + 1]"
                            name="project_shipping_address_{{i + 2}}" autocomplete="off">
                        </kendo-textbox>
                        <div class="ml-2 d-flex"
                            *ngIf="companyFormPayload.project_shipping_address?.length >= 3 || (companyFormPayload.project_shipping_address?.length < 3 && i + 1 === companyFormPayload.project_shipping_address?.length - 1)">
                            <span class="icon-button-wrapper">
                                <button mat-flat-button type="button" color="input-color"
                                    matTooltip="Delete Shipping Address" class="delete-shipping-btn"
                                    (click)="deleteShippingAddress(i + 1)">
                                    <img src="assets/images/delete-icon.svg" height="20" />
                                </button>
                            </span>
                            <span class="icon-button-wrapper"
                                *ngIf="companyFormPayload.project_shipping_address.length < 3 && i + 1 === companyFormPayload.project_shipping_address.length - 1">
                                <button mat-flat-button type="button" color="input-color"
                                    matTooltip="Add Shipping Address" (click)="addShippingAddress()">
                                    <img src="assets/images/add-circle-icon.svg" height="20" />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #editShippingAddress>
                <div class="col-12">
                    <div class="form-field mb-2">
                        <div class="label-text">Project Shipping Address 1</div>
                        <div
                            [ngClass]="{'d-flex align-items-center': companyFormPayload.project_shipping_address.length === 1, 'w-100': companyFormPayload.project_shipping_address.length !== 1}">
                            <kendo-textbox [(ngModel)]="companyFormPayload.project_shipping_address[0]"
                                (ngModelChange)="updateShippingAddress(0, $event)" name="project_shipping_address_1"
                                autocomplete="off">
                            </kendo-textbox>
                            <div *ngIf="companyFormPayload.project_shipping_address.length === 1">
                                <span class="icon-button-wrapper">
                                    <button mat-flat-button type="button" color="input-color"
                                        matTooltip="Add Shipping Address" (click)="addShippingAddress()">
                                        <img src="assets/images/add-circle-icon.svg" height="20" />
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let address of companyFormPayload.project_shipping_address.slice(1); let i = index; trackBy: trackByFn"
                        class="form-field mb-2">
                        <div class="label-text">Project Shipping Address {{i + 2}}</div>
                        <div
                            [ngClass]="{'d-flex align-items-center': true, 'w-100': companyFormPayload.project_shipping_address.length < 3 && i + 1 !== companyFormPayload.project_shipping_address.length - 1}">
                            <kendo-textbox [(ngModel)]="companyFormPayload.project_shipping_address[i + 1]"
                                (ngModelChange)="updateShippingAddress(i+1, $event)"
                                name="project_shipping_address_{{i + 2}}" autocomplete="off">
                            </kendo-textbox>
                            <div class="ml-2 d-flex"
                                *ngIf="companyFormPayload.project_shipping_address.length >= 3 || (companyFormPayload.project_shipping_address.length < 3 && i + 1 === companyFormPayload.project_shipping_address.length - 1)">
                                <span class="icon-button-wrapper">
                                    <button mat-flat-button type="button" color="input-color"
                                        matTooltip="Delete Shipping Address" class="delete-shipping-btn"
                                        (click)="deleteShippingAddress(i + 1)">
                                        <img src="assets/images/delete-icon.svg" height="20" />
                                    </button>
                                </span>
                                <span class="icon-button-wrapper"
                                    *ngIf="companyFormPayload.project_shipping_address.length < 3 && i + 1 === companyFormPayload.project_shipping_address.length - 1">
                                    <button mat-flat-button type="button" color="input-color"
                                        matTooltip="Add Shipping Address" (click)="addShippingAddress()">
                                        <img src="assets/images/add-circle-icon.svg" height="20" />
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Phone</div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.phone" name="phone" [maxlength]="25"
                        autocomplete="off" [pattern]="customPatterns.phoneNumber">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.phone?.length || 0}}/25
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Alt Phone Number</div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.alt_phone_number" [maxlength]="25"
                        name="alt_phone_number" autocomplete="off" [pattern]="customPatterns.phoneNumber">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.alt_phone_number?.length || 0}}/25
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Fax Number</div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.fax_number" name="fax_number" autocomplete="off"
                        [maxlength]="25" [pattern]="customPatterns.phoneNumber">
                    </kendo-textbox>
                    <mat-hint class="d-flex justify-content-end">
                        {{companyFormPayload.fax_number?.length || 0}}/25
                    </mat-hint>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Industry Type <sup>*</sup></div>
                    <kendo-dropdownlist [data]="industryTypeSourceList" [textField]="'key'" [valueField]="'value'"
                        [valuePrimitive]="true" [defaultItem]="commonSettings.defaultItems.fieldsValueKey"
                        [loading]="companyFormLoaderModel.isIndustryTypeLoading"
                        [(ngModel)]="companyFormPayload.industry_type" name="industry_type" placeholder="Select"
                        required>
                    </kendo-dropdownlist>
                </div>
            </div>
            <div class="col-12">
                <div class="form-field mb-2">
                    <div class="label-text">Description</div>
                    <kendo-textarea [(ngModel)]="companyFormPayload.description" name="description" [rows]="5"
                        autocomplete="off" resizable="vertical">
                    </kendo-textarea>
                </div>
            </div>
            <div class="col-6">
                <div class="form-field mb-2">
                    <div class="label-text">Company Owner</div>
                    <kendo-textbox [(ngModel)]="companyFormPayload.created_by" name="created_by" autocomplete="off"
                        [disabled]="true">
                    </kendo-textbox>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-center d-none"
                [ngClass]="{'archive': !isRestore, 'restore': isRestore}" (click)="onShowArchiveConfirmation()">
                <ng-container *ngIf="formType === commonConstants.formTypeConstants.edit">
                    <img src="assets/images/{{ isRestore ? 'restore-icon.svg' : 'archive.svg' }}" height="16" />
                    <b class="ms-2">{{ isRestore ? 'Restore' : 'Archive' }}</b>
                </ng-container>
            </div>
            <div class="contact-wrapper">
                <div class="contact-title my-3">
                    Contact Association
                </div>
                <div class="row mb-3">
                    <div class="col-6 p-0">
                        <div class="form-field mb-0">
                            <kendo-dropdownlist [data]="contactList" [textField]="'contact_name'" [valueField]="'id'"
                                [valuePrimitive]="true" [loading]="companyFormLoaderModel.isFormLoading"
                                [defaultItem]="commonSettings.defaultItems.contactNameId" [filterable]="true"
                                [itemDisabled]="itemDisabled"
                                [kendoDropDownFilter]="commonSettings.dropDownFilterSettings"
                                [(ngModel)]="selectedContact" name="contact_name" placeholder="Select">
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="col-6 p-0 d-flex justify-content-end align-items-center">
                        <div class="button-wrapper">
                            <span class="icon-button-wrapper">
                                <button mat-flat-button type="button" color="primary" (click)="addContacts()"
                                    [disabled]="!selectedContact" matTooltip="Contact Association">
                                    <img src="assets/images/add-circle-icon.svg" height="20" class="add-icon" />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="table-responsive mt-2" *ngIf="companyFormPayload.association.length > 0">
                    <table class="table associate-table table-hover">
                        <thead>
                            <tr class="table-header">
                                <th>Name</th>
                                <th>Industry Type</th>
                                <th class="w-100px">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let contact of companyFormPayload.association let i = index">
                                <td class="wrap-contact-company" [matTooltip]="contact.contact_name">
                                    {{ contact.contact_name }}
                                </td>
                                <td class="wrap-contact-company" [matTooltip]="contact.industry_type">
                                    {{ contact.industry_type }}
                                </td>
                                <td>
                                    <div class="grid-icon-btn-wrapper">
                                        <button mat-flat-button type="button" color="error"
                                            matTooltip="Delete Association" class="m-0"
                                            (click)="onDeleteAssociation([i])">
                                            <img src="assets/images/delete-icon.svg" height="16" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-flat-button type="button" color="secondary" (click)="onClose()">
                Back
            </button>
            <button mat-flat-button type="submit" color="primary" [disabled]="isRestore || companyNameExist">
                Save
            </button>
        </div>
    </form>
</div>